<template>
  <l-map ref="myMap" :zoom="zoom" :options="mapOptions" @update:zoom="zoomUpdate" :staticMap="staticMap" :tap="false"
    :center="center">
    <v-tooltip top v-for="(e, id) in pointsMarket" :key="id">
      <template v-slot:activator="{ on, attrs }">
        <l-marker v-bind="attrs" v-on="on" :lat-lng="[e.ubicacion.latitud, e.ubicacion.longitud]" :draggable="false"
          @click="openPopUp(e, 'marker')" :icon="getIcon(e.unidad)">
          <l-popup ref="popup" style="width: 300px;" v-if="controlsShow">
            <h3 class="mt-6 pr-4">{{ e?.titulo }}</h3>
            <v-img class="mt-4" :src="`${env.VUE_APP_LA_ENEE}/inversion-publica/api/v1/proyectos/${e?.relativePath}`"
              style="border-radius: 10px;">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center"
                  style="width: 100%; height: 100%; background-color: #f0f0f0;">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <p class="text-caption" style="white-space: pre-line !important;">
              {{ truncateText(e.descripcion) }}
            </p>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(e)">
                <span style="color: #fff !important; text-transform: initial !important;">Ver más</span>
              </v-btn>
            </v-card-actions>
          </l-popup>
        </l-marker>
      </template>
      <span>{{ e.nombreCorto }}</span>
    </v-tooltip>
    <l-tile-layer :url="url" />
    <LPolyline v-for="(l, index) in lines" :color="l.color" :fill="l.fill" :weight="l.weight" :key="index"
      :lat-lngs="l.vector.coordenadas">
      <l-popup ref="popup2" v-if="controlsShow">
        <h3 class="pr-4">{{ l?.titulo }}</h3>
        <p class="text-caption">
          {{ l.descripcion }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(l)">
            <span style="color: #fff !important; text-transform: initial !important;">Ver más</span>
          </v-btn>
        </v-card-actions>
      </l-popup>
    </LPolyline>
    <LPolygon v-for="(p, index) in poligons" :key="index" :fill="p.vector.fill" :stroke="true" :weight="p.vector.weight"
      :fillOpacity="p.vector.fillOpacity" :lat-lngs="p.vector.coordenadas" :fillColor="p.vector.fillColor"
      :color="p.vector.color">
      <l-popup ref="popup3" v-if="controlsShow">
        <h3 class="pr-4">{{ p?.titulo }}</h3>
        <p class="text-caption">
          {{ p.descripcion }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(p)">
            <span style="color: #fff !important; text-transform: initial !important;">Ver más</span>
          </v-btn>
        </v-card-actions>
      </l-popup>
    </LPolygon>
  </l-map>
</template>

<script>
import L, { latLng } from "leaflet";
import { mapActions, mapState } from 'vuex';
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPolygon,
  LPopup,
  LControlZoom,
  LTooltip,
  LPolyline,
} from "vue2-leaflet";
import { env } from "@/environment/env";

require("leaflet-routing-machine");
require("leaflet-control-geocoder");

export default {
  name: "Mapa",
  props: ["pointsMarket", 'staticMap', 'poligons', 'lines', 'controlsShow', 'allProyectsLocal'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    "l-icon": LIcon,
    "l-popup": LPopup,
    "l-control-zoom": LControlZoom,
    "l-tooltip": LTooltip,
    LPolyline,
  },
  data() {
    return {
      zoom: 10,
      center: latLng(14.0625764, -87.2226859),
      url: "https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=iEIhXFKiBQgbYsHy0112N2SH88oI5eUzmnH45nUlM0mkiV8Y8wCXIhxPF7hPR5jX",
      mapOptions: {
        zoomSnap: 1,
      },
      currentZoom: 11.5,
    };
  },
  computed: {
    env() {
      return env
    },
    iconTransmision() {
      return L.icon({
        iconUrl: require("@/assets/icons-03.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    iconGeneracion() {
      return L.icon({
        iconUrl: require("@/assets/icons-02.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    ninguno() {
      return L.icon({
        iconUrl: require("@/assets/ninguno.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    iconDistribucion() {
      return L.icon({
        iconUrl: require("@/assets/icons-01.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
  },
  methods: {
    ...mapActions(['selectProyect_']),
    truncateText(text, maxChars = 240) {
      if (text.length <= maxChars) {
        return text;
      }
      return text.slice(0, maxChars) + '...';
    },
    getIcon(unidad) {
      switch (unidad) {
        case 'Distribución':
          return this.iconDistribucion;
        case 'Transmisión':
          return this.iconTransmision;
        case 'Generación':
          return this.iconGeneracion;
        default:
          return this.ninguno;
      }
    },
    openPopUp(latLng, caller) {
      this.caller = caller;
      this.$refs.features.mapObject.openPopup(latLng);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    sendProyect(proyecto) {
      const proyect = this.allProyectsLocal.find(x => x.id == proyecto.id);
      this.selectProyect_(proyect);
      this.$router.push({ path: `/infoProyect/${proyect.id}` });
    },
    centerZoom() {
      this.$nextTick(() => {
        this.map = this.$refs.myMap.mapObject;
        const points = this.pointsMarket.map(item => latLng(item.ubicacion.latitud, item.ubicacion.longitud));
        // const poligons = this.poligons.length > 0 ? this.poligons.flatMap(item => item.vector.ubicacion.map(p => latLng(p.lat, p.lng))) : [];

        const poligonsPoints = this.poligons.flatMap(polygon => polygon.vector.coordenadas.map(coord => latLng(coord.lat, coord.lng)));


        const linePoints = this.lines.flatMap(line => line.vector.coordenadas.map(coord => latLng(coord.lat, coord.lng)));
        const pointsAll = [...points, ...linePoints, ...poligonsPoints];
        this.map.fitBounds(pointsAll, {
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50]
        });
      });
    },
  },
  watch: {
    lines() {
      setTimeout(this.centerZoom, 1000);
    },
    pointsMarket() {
      setTimeout(this.centerZoom, 1000);
    }
  },
  mounted() {
    this.$nextTick(this.centerZoom);
  },
};
</script>

<style lang="scss">
span {
  color: #62c3db !important;
}

.leaflet-pane,
.leaflet-marker-icon,
.leaflet-tile-container,
.leaflet-zoom-box,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50% !important;
}

.leaflet-left {
  top: none !important;
  position: fixed !important;
  left: 10px !important;
  top: 100px !important;
}

.btn-refresh {
  position: fixed;
  right: 10px;
  z-index: 999;
}

.leaflet-bottom {
  top: none !important;
  left: 0 !important;
  bottom: 10px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
  background-clip: padding-box;
  width: 56px;
  border-radius: 50px !important;
  overflow: hidden;
  box-shadow: #07061310 0px 0px 20px !important;

  .leaflet-control-zoom-in {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
  }

  .leaflet-control-zoom-out {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-popup-close-button {
  margin-top: 9px;
  font-weight: bold;
  font-size: 20px;
}

.routeAlt {
  box-shadow: #62c3db 0px 10px 10px;
}

.leaflet-routing-container {
  display: none;
}

.motorista {
  transition: 0.5s ease;
}

.route {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}

.marker-custom {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}

div.leaflet-marker-icon.custom-marker {
  color: #ea2227;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -8px;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #ea2227;
    box-shadow: 0px 0px 20px rgba(#ea2227, 0.8);
    border-radius: 8px;
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    box-sizing: border-box;
    background-color: white;
    border: 3px solid #ea2227;
    box-shadow: 0px 0px 20px rgba(#62c3db, 0.8);
    border-radius: 5px;
    z-index: -1;
  }

  .marker-pin {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 20px rgba(#62c3db, 0.2);
    height: auto;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 18px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px) scale(1.2, 1.2) !important;
      box-shadow: 0px 5px 20px rgba(#62c3db, 0.5);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      margin-left: -5px;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background-color: white;
      border-bottom-right-radius: 2px;
    }

    i {
      color: #62c3db;
      background-color: #62c3db;
      background-color: #7fb7ca;
      font-size: 16px;
      float: left;
      padding: 8px;
      position: relative;
      border-radius: 18px;
    }
  }
}

.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 9px !important;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  color: #ffffff !important;
  text-decoration: none;
  border-radius: 16px !important;
  background: #393939 !important;
}

div.leaflet-marker-icon.custom-marker .marker-pin p {
  text-align: center;
  font-weight: revert;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0 !important;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 999 !important;
  pointer-events: none;
}

.leaflet-popup-content {
  margin: 26px 20px 16px 20px !important;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  display: none !important;
}

.leaflet-popup-content-wrapper {

  border-radius: 10px !important;
}
</style>

<template>
  <div class="main-wrap-home">
    <div class="wrap-l" v-if="!loading">
      <div class="card-t-l">
        <div color="#fff" class="avances a-r card-rounded" elevation="0" height="100%">

          <div class="header-select">
            <div class="title-chart">
              Plan de Expansión Inversión 2023 2030 <br>
              <span class="subtext-chart">En millones de Lempiras</span>
            </div>
            <div class="select-chart">
              <v-select outlined rounded label="Unidades " width="100px " hide-details dense :items="unidades"
                v-model="uni" style="z-index: 100; width: 100px !important;" @change="unidadGet">
              </v-select>
            </div>
          </div>
          <div class="chart">
            <v-chart class="chart-graphic" :option="chartOption" height="100%" width="100%" />
          </div>
          <div class="footer-chart">
            <div class="item-chart" v-for="(x, i) in chartOption.series" :key="i">
              <div class="item-circulo" :style="{ backgroundColor: x.itemStyle.color }"></div>
              {{ x.name }}
            </div>
          </div>


        </div>

        <v-card scrollable color="#fff" class="avances a-l card-rounded" height="100%" elevation="0">
          <v-card-title>{{ uni == 'all' ? 'Todas las unidades' : uni }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="wrap-proyectos-list">
            <v-list dense>
              <v-list-item two-line v-for="(x, i) in findProyects" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ x.titulo }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption-v2">{{ x.descripcion }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ x.presupuestoInicial | formatterAmount }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="investment-total">
              <span class="investment-title">Total de inversión</span>
              <span> {{ mainCalcule.costoTotal | formatterAmount }} </span>
              <v-progress-linear :value="((mainCalcule.costoTotal / 100000000) * 100) + 1" height="8px" rounded
                color="primary"></v-progress-linear>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <div class="card-b-l">

        <v-card color="#fff" class="mapa-container card-rounded" elevation="0" @click="$router.push('/proyects')">
          <div class="map-wrapper">
            <maps :staticMap="true" :controlsShow="false" :pointsMarket="pointsProyects" :poligons="polygonsProyects"
              :lines="linesProyects" :allProyectsLocal="allProyectsLocal" class="map-content" />
          </div>
        </v-card>
      </div>
    </div>
    <div class="wrap-r" v-if="!loading">
      <v-card class="shadow card-list-calculate card-rounded" width="100%" color="#FAFAFA" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">980,000</h2>
          <div
            style="position:absolute;  bottom:20px; left:20px; width:calc(100% - 20px); justify-content: space-between; align-items: stretch ; display: flex; flex-direction: column;">
            <div style="width: 70%;">
              <p class="investment-description">Inversión social</p>
              <p class="investment-description mt-2">Familias subsidiadas</p>
            </div>
            <v-icon x-large color="primary" class="investment-icon">mdi-human-male-female-child</v-icon>
          </div>
        </div>
      </v-card>
      <v-card color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.presupuestoInicial | formatterAmount }}</h2>
          <div
            style="position:absolute;  bottom:20px; left:20px; width:calc(100% - 20px); justify-content: space-between; align-items: stretch ; display: flex; flex-direction: column;">
            <p>Presupuesto Inicial</p>
          </div>
        </div>
      </v-card>
      <v-card dark color="success" class="shadow card-list-calculate card-rounded" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount" style="color: #fff !important;">{{ mainCalcule.presupuestoInvertido |
            formatterAmount }}</h2>
          <div
            style="position:absolute;  bottom:20px; left:20px; width:calc(100% - 20px); justify-content: space-between; align-items: stretch ; display: flex; flex-direction: column;">
            <p class="investment-description" style="color: #fff !important;">Presupuesto Invertido</p>
            <v-icon size="50px" class="investment-icon">mdi-trending-up</v-icon>
          </div>
        </div>
      </v-card>
      <!-- <div class="wrap-empleos">
        <v-card dark color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
          <div class="card-money">
            <h2 class="investment-amount">{{ mainCalcule.productosSubcontratados | currencySinL }}</h2>
            <p>Productos <br> subcontratados</p>
          </div>
        </v-card>
        <v-card dark color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
          <div class="card-money">
            <h2 class="investment-amount">{{ mainCalcule.empleosGenerados | currencySinL }}</h2>
            <p>Empleos a generar</p>
          </div>
        </v-card>
      </div>
      <v-card elevation="0" class="shadow card-list-calculate card-rounded">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.comunidadesBeneficiarias | currencySinL }}</h2>
          <p>Comunidades Beneficiarias</p>
          <v-icon x-large color="primary" class="investment-icon">mdi-home-group</v-icon>
        </div>
      </v-card>
      <v-card class="shadow card-list-calculate card-rounded" width="100%" color="#FAFAFA" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.familiasBeneficiarias | currencySinL }}</h2>
          <p>Familias Beneficiarias</p>
          <v-icon x-large color="primary" class="investment-icon">mdi-human-male-female-child</v-icon>
        </div>
      </v-card> -->
      <v-card color="#fff" class="card-rounded shadow card" height="50%" elevation="0" style="    overflow: clip;">
        <v-card-title>
          Departamentos
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="scrollable-content">
          <v-list dense>
            <v-list-item v-for="(x, i) in departamentosAll" :key="i">
              <v-list-item-content>
                <v-list-item-title class="department-title">
                  <b>{{ x.nombre }}</b>
                  <v-chip color="primary" outlined small> {{ x.cantidad }}</v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <div class="loading-home" v-if="loading">
      <img src="@/assets/campaña.svg" width="200px" alt="logo" class="logo-loading">
      <v-progress-circular indeterminate color="#62c3db"></v-progress-circular>

    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts'

import axios from "axios";
import { mapActions, mapState } from "vuex";
import maps from '@/components/mapa.vue'
import { env } from "@/environment/env";
const gradients = [
  ['#62c3db'],
  ['#62c3db'],
  ['red', 'orange', 'yellow'],
  ['#62c3db', 'violet'],
  ['#62c3db', '#62c3db00'],
  ['#f72047', '#ffd200', '#1feaea'],
]
export default {
  name: 'Home',

  components: {
    VChart,
    maps,
  },
  data() {
    return {
      bidding: [],
      uni: 'all',
      fill: true,
      selectedGradient: gradients[4],
      dataChart: {},
      gradients,
      padding: 0,
      loading: true,
      filterDialog: false,
      selectedUnit: null,
      allProyectsLocal: [],
      unitOptions: [
        { text: 'Distribución', value: 'Distribución' },
        { text: 'Transmisión', value: 'Transmisión' },
        { text: 'Generación', value: 'Generación' },
      ],
      radius: 10,
      value: [0, 5, 6, 20, 70, 80],
      labels: [2022, 2023, 2024, 2025, 2026, 2027,],
      width: 10,
      points: [
        {
          "latitud": 14.119543275065197,
          "longitud": -87.20348376560112

        },
        {
          "latitud": 15.508250601133668,
          "longitud": -88.02032211811738

        },

        {
          "latitud": 14.467370426449762,
          "longitud": -87.63191003459949

        },

        {
          "latitud": 15.268162955055502,
          "longitud": -83.78571077364381

        },
      ],
      unidades: [
        {
          text: 'Distribución',
          value: 'Distribución'
        },
        {
          text: 'Transmisión',
          value: 'Transmisión'
        },
        {
          text: 'Generación',
          value: 'Generación'
        },
        {
          text: 'Todas',
          value: 'all'
        }
      ],
      selectProyectAll: [],


    }

  },
  computed: {
    sortPage() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.bidding.sort(compare);
    },

    chartOption() {

      const data = this.dataChart;
      const dataSeries = data.data.map(x => {
        const color = {
          'Distribución': ['#e20714', '#ff7f7f', '#e20714',],
          'Transmisión': ['#ffc107', '#ffe082', '#ffc107',],
          'Generación': ['#5FD0DF', '#a0e9f8', '#5FD0DF',],
        }

        const series = {
          name: x.name,
          type: 'line',
          smooth: true,
          data: x.data,
          stack: 'Total',
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },

          label: {
            show: true,
            position: 'top',
            formatter: function (params) {
              const formatter = new Intl.NumberFormat("en-US");
              return `L ${formatter.format(params.value)}`;
            }
          },
          lineStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: color[x.name][0] },
                { offset: 1, color: color[x.name][1] }
              ],

            },
            width: 3
          },
          itemStyle: { color: color[x.name][0] },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: color[x.name][0] },
                { offset: 1, color: color[x.name][1] },

              ],
            }
          },
          showSymbol: false,
        }
        return series;
      });

      return {

        tooltip: {
          trigger: 'axis',
          formatter: function (params) {

            const formatter = new Intl.NumberFormat("en-US");

            let tooltipContent = `<div class="tooltip-content">`;
            for (let i = 0; i < params.length; i++) {
              tooltipContent += `
              <div class="tooltip-content-title"><div style="background-color: ${params[i].color} !important; width: 10px; height: 10px; border-radius: 50%; display: inline-block; margin-right: 5px; "></div>  <b>${params[i].seriesName}</b> (${params[i].axisValueLabel}) </div>
              <div class="tooltip-content-value" > L ${formatter.format(params[i].value)} </div>
            `;
            }
            tooltipContent += `</div>`;
            return tooltipContent;
          }
        },

        xAxis: {
          type: 'category', data: data.labels, axisLabel: {

            interval: 0,
            align: 'center',
            verticalAlign: 'middle',
            fontSize: 12,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (valor) {
              const formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
              if (valor >= 1000000000) {
                return "L " + formatter.format(valor / 1000000.0);
              }
              else if (valor >= 1000000) {
                return "L " + formatter.format(valor / 1000000.0);
              }
              else if (valor >= 1000) {
                return "L " + formatter.format(valor / 1000.0);
              }

              return `L ${formatter.format(valor)}`;
            }
          }
        },
        series: dataSeries.filter(x => this.uni == 'all' ? true : x.name === this.uni),
      }
    },
    findProyects() {
      const proyectsLocal = Object.assign([], this.selectProyectAll);
      return proyectsLocal.filter(x => this.uni == 'all' ? x : this.uni == x.unidad)

    },
    mainCalcule() {
      const proyects = this.selectProyectAll.filter(x => this.uni === 'all' || this.uni === x.unidad);

      return proyects.reduce((acc, element) => {
        acc.presupuestoInicial += element.presupuestoInicial;
        acc.presupuestoInvertido += element.presupuestoInvertido;
        acc.productosSubcontratados += element.productosSubcontratados;
        acc.costoTotal += element.costoTotal;
        acc.familiasBeneficiarias += element.familiasBeneficiarias;
        acc.comunidadesBeneficiarias += element.comunidadesBeneficiarias;
        acc.empleosGenerados += element.empleosGenerados;
        return acc;
      }, {
        presupuestoInicial: 0,
        presupuestoInvertido: 0,
        productosSubcontratados: 0,
        costoTotal: 0,
        familiasBeneficiarias: 0,
        comunidadesBeneficiarias: 0,
        empleosGenerados: 0,
      });
    },

    departamentosAll() {
      const departamentosCount = this.selectProyectAll.reduce((acc, proyect) => {
        const departamento = proyect.ubicacionGeografica?.departamento?.nombre;
        if (departamento) {
          acc[departamento] = (acc[departamento] || 0) + 1;
        }
        return acc;
      }, {});

      return Object.entries(departamentosCount).map(([nombre, cantidad]) => ({
        nombre,
        cantidad
      }));
    },
    pointsProyects() {
      var points = [];
      for (let i = 0; i < this.selectProyectAll.length; i++) {
        const proyect = this.selectProyectAll[i];

        if (proyect.ubicacionGeografica.ubicacion.latitud && !proyect.vector) {

          var obj = {
            id: proyect.id,
            titulo: proyect.nombreCorto,
            descripcion: proyect.descripcion,
            portada: proyect.portada,
            unidad: proyect.unidad,
            ubicacion: proyect.ubicacionGeografica.ubicacion
          }
          points.push(obj)
        }

      }
      return points || []
    },
    polygonsProyects() {
      var Poligons = [];

      var proyectos = this.selectProyectAll.filter(x => x.vector && x.vector.tipo == "polygon")
      for (let i = 0; i < proyectos.length; i++) {
        const proyect = proyectos[i];


        var poli = {
          id: proyect.id,
          titulo: proyect.nombreCorto,
          descripcion: proyect.descripcion,
          portada: proyect.portada,
          unidad: proyect.unidad,
          coordenadas: proyect.ubicacionGeografica.ubicacion,
          vector: proyect.vector
        }
        Poligons.push(poli);
      }


      return Poligons || []
    },
    linesProyects() {
      var Lines = [];

      var proyectos = this.selectProyectAll.filter(x => x.vector && x.vector.tipo == "line")
      for (let i = 0; i < proyectos.length; i++) {
        const proyect = proyectos[i];


        var line = {
          id: proyect.id,
          titulo: proyect.nombreCorto,
          descripcion: proyect.descripcion,
          portada: proyect.portada,
          unidad: proyect.unidad,
          coordenadas: proyect.ubicacionGeografica.ubicacion,
          vector: proyect.vector
        }
        Lines.push(line);
      }


      return Lines || []
    },

  },

  filters: {
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `$ ${formatter.format(amount)}`;
    },
    formatterAmount(valor) {

      if (valor >= 1000000000) {
        return "L " + (valor / 1000000000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " MM";
      }
      else if (valor >= 1000000) {
        return "L " + (valor / 1000000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " M";
      }
      else if (valor >= 1000) {
        return "L " + (valor / 1000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " K";
      }

      return "L " + valor;

    },
    currencySinL(amount) {
      const formatter = new Intl.NumberFormat("en-US");
      return `${formatter.format(amount)}`;
    },
  },
  methods: {
    applyFilter() {
      this.filterDialog = false;
      this.uni = this.selectedUnit;
    },
    ...mapActions(["setProyects_"]),
    unidadGet(e) {

      if (e == 'all') {
        this.selectProyectAll = Object.assign([], this.allProyectsLocal);
      } else {

        this.selectProyectAll = this.allProyectsLocal.filter(x => x.unidad == e);
        this.Unid = this.selectProyectAll[0].unidad
      }
      this.dialog = false;
    },
    async getGrafico() {
      await axios
        .get(`${env.VUE_APP_LA_ENEE}/inversion-publica/api/v1/plan-inversion/grafico`, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Credentials": "true",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        })
        .then((response) => {
          this.dataChart = response.data;
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });

    },

  },
  mounted() {
    this.loading = true;
    this.$nextTick(async () => {
      await axios
        .get(`${env.VUE_APP_LA_ENEE}/inversion-publica/api/v1/proyectos`, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Credentials": "true",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        })
        .then((response) => {
          this.allProyectsLocal = response.data;
          this.selectProyectAll = Object.assign([], this.allProyectsLocal);
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });

    })
    this.getGrafico();
  }
}
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";

.card-rounded {
  border-radius: 30px !important;
}

.scrollable-content {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 120px;
}

.investment-total {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
}

.investment-title {
  font-size: 20px;
  color: #616161 !important;
}

.investment-amount {
  color: #5FD0DF;
  line-height: 1.5em;
  position: absolute;
  top: 20px;
  left: 20px;
}

.investment-subtitle {
  line-height: 1em;
  font-size: 16px;
  margin-bottom: 5px;
}

.investment-description {
  font-weight: 200;
  margin-bottom: 0px;
}

.investment-icon {
  position: absolute !important;
  bottom: 0px;
  right: 20px;

}

.wrap-empleos {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100px;
}

.map-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none !important;
  cursor: pointer;
}

.map-content {
  border-radius: 30px;
  pointer-events: none !important;
}

.wrap-proyectos-list {
  height: calc(100% - 152px);
  overflow-y: scroll;

  @include responsive(mobile) {
    height: calc(30vh) !important;
  }
}

.text-imagen {
  position: absolute;
  bottom: 0px;
  color: #fff;
  width: 100%;
  height: auto;
  left: 0;
  background-color: rgba(0, 0, 0, 0.344);
}

.main-wrap-home {
  width: calc(100% - 20px);
  height: calc(100vh - 90px);
  margin-top: 70px;
  display: flex;
  gap: 16px;

  @include responsive(small) {
    flex-direction: column;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
  }

  @include responsive(mobile) {
    flex-direction: column;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrap-l {
    width: 74%;
    height: 100%;
    display: flex;
    flex-direction: column;

    gap: 16px;

    @include responsive(small) {
      width: 100%;
    }

    @include responsive(mobile) {
      width: 100%;
    }

    .card-t-l {
      height: 48%;
      width: 100%;
      display: flex;
      gap: 16px;

      @include responsive(small) {
        width: 100%;
        flex-direction: column;
      }

      @include responsive(mobile) {
        width: 100%;
        flex-direction: column;
        gap: 16px;
      }

      .avances {
        width: 50%;

        @include responsive(small) {
          width: 100%;
          height: 400px !important;
        }

        @include responsive(mobile) {
          width: 100%;
          height: 100% !important;
        }
      }

      .a-r {
        overflow: hidden;
        background-color: #fff !important;
        padding: 16px;
        position: relative;

        .header-select {
          height: 40px;
          width: 100%;
          position: absolute;
          top: 16px;

          bottom: 0;
          z-index: 1;
          left: 0;
          padding: 0 16px;

          display: flex;
          align-items: center;
          justify-content: center;

          .title-chart {
            width: calc(100% - 100px);
            font-weight: 600;
          }

          .subtext-chart {
            font-size: 12px;
            font-weight: 400;
            color: #616161 !important;
          }

          .select-chart {
            width: 200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          // background-color: blue;
        }

        .chart {
          position: relative;
          width: 100%;
          height: 100%;
          // background-color: red;
          padding: 10px;
        }

        @include responsive(mobile) {
          height: 300px !important;
        }
      }

      .a-l {

        @include responsive(mobile) {
          height: 100% !important;
        }
      }
    }

    .card-b-l {
      height: 50%;
      width: 100%;
      display: flex;
      gap: 16px;


      @include responsive(small) {
        width: 100%;
      }

      @include responsive(mobile) {
        width: 100% !important;
        flex-direction: column;
        height: auto;
        gap: 16px;
      }

      .card {
        width: 28%;
        height: 100%;

        @include responsive(small) {
          width: 100%;
        }

        @include responsive(mobile) {
          width: 100%;
        }
      }
    }
  }

  .wrap-r {
    height: 100%;
    width: 26%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;


    @include responsive(small) {
      width: 100%;
    }

    @include responsive(mobile) {
      width: 100%;
    }

    .card-list-calculate {
      height: 100%;
      min-height: 120px;
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
    }
  }
}

.leaflet-control-container {
  display: none;
}

.department-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-money {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-content: center;
  align-items: flex-start;
  height: 100px;
  width: 100%;

  padding-left: 20px;

  h2 {
    font-size: 2.1rem !important;
    // line-height: 1em;

    @include responsive(mobile) {
      font-size: 2rem !important;
    }

    @include responsive(Ipad) {
      font-size: 1rem !important;
    }

    @include responsive(IpadPro) {
      font-size: 1.5rem !important;
    }
  }

  p {
    line-height: 10px;
    font-size: 14px;
    font-weight: 800;
  }
}

.mapa-container {
  width: 100%;
  height: 100%;

  @include responsive(mobile) {
    width: 100%;
    height: 300px;
  }
}

.linea-proyec {
  position: absolute;
  left: 10px;
  top: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  height: 100%;
}

.loading-home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.tooltip-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  transform: rotate(45deg);

}

.tooltip-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltip-content-value {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
}

.footer-chart {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  left: 16px;
  height: 20px;
  width: 100%;

  .item-chart {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;

  }

}

.item-circulo {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #62c3db;
}

.chart-graphic {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
}
</style>